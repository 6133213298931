body{
    overflow: hidden
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: rgb(30, 30, 30);
    color: #D2D2D2;
}

.header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgb(60, 60, 60);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo{
    margin: 20px;
}
.navigation{
    margin: 20px;
}

.main {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: 3fr 6.6fr 0.4fr;
}

.card__preview {
    outline: 1px solid rgb(60, 60, 60);
    background: rgb(48, 48, 48);
    padding: 50px;
}

.graph__view {
}

.controls{
    border-left: 1px solid rgb(60, 60, 60);
}
