.wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10%;
    background: #BB85F8;
    outline: none;
    border: none;
    width: 80%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}