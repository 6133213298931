.wrapper{
    padding: 20px;
    width: 500px;
    height: fit-content;
    background: #9A9A9A;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    border: 2px solid black;
}

.node__body{
    flex: 1 1 auto;
    width: 100%;
    min-height: 400px;
    padding: 20px;

}